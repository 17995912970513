$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1450px
);

$px10: 0.625rem;
$px12: 0.75rem;
$px13: 0.8125rem;
$px14: 0.875rem;
$px15: 0.9375rem;
$px16: 1rem;
$px18: 1.125rem;
$px20: 1.25rem;
$px21: 1.3125rem;
$px23: 1.4375rem;
$px24: 1.5rem;
$px27: 1.6875rem;
$px28: 1.75rem;
$px30: 1.875rem;
$px34: 2.125rem;
$px36: 2.25rem;
$px40: 2.5rem;
$px42: 2.625rem;
$px45: 2.8125rem;
$px48: 3rem;
$px50: 3.125rem;
$px55: 3.4375rem;
$px58: 3.625rem;
$px70: 4.375rem;
$px100: 6.25rem;
$px150: 9.375rem;
$px320: 20rem;



@media (min-width: 1200px) {
    .container {
        max-width: 1390px;
    }

    .site-footer > div.container {
        max-width: 1290px;
    }

}
