.main-categories-filter {
    .main-category-item {
        text-decoration: none !important;
        position: relative;
            transition: all 300ms ease;
        h2 {
            position: relative;
            z-index: 2;
            max-width: 140px;
            color: $light-font-color;
            font-size: 30px;
            font-weight: 700;
            transition: all 300ms ease;
        }
        &:before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $dark-bg-color;
            opacity: .8;
        }
        &:after {
            position: absolute;
            z-index:3;
            content: "";
            bottom:0;
            left: 0;
            height: 3px;
            width: 0;
            transition: all 300ms ease;
            display: block;
            background-color: $main-color;
        }
        &:hover, &.active {
            &:after {
                width: 100%;
            }
            h2 {
                color: $main-color;
            }
        }
    }
}

.category-filter {

    .category-filter-item-lvl-1, .category-filter-item  {
        color: $font-color;
        transition: all 300ms ease;
        text-decoration: none !important;
        h3 {
            font-size: 22px;
            font-weight: 700;
            color: $font-color;
            transition: all 300ms ease;
        }
        &:hover, &.active {
            color: $secondaryColor;
            h3 {
                color: $main-color;
            }
        }
    }
    .category-filter-item {

        .subcategories-list {
            margin: 0;
            list-style: none;
            padding-top: $px20;
            padding-bottom: $px20;
            padding-left: 10px;
            border-bottom: 1px solid #D9D9D9;

            li.sub-cat {
                padding-left: 10px;
            }

            li.sub-cat ul.sub-menu {
                list-style: none;
                padding-left: 0px;
            }

            li.sub-cat ul.sub-menu li a {
                font-family: Roboto,sans-serif;
                line-height: 2.25rem;
                font-size: 1.125rem;
                border-bottom: none;
                color: #000;
            }

            li.sub-cat ul.sub-menu li ul {
                padding-left: 20px;
            }

            li.sub-cat ul.sub-menu li ul li a {
                font-family: Roboto,sans-serif;
                line-height: 2rem;
                font-size: 1rem;
                border-bottom: none;
                color: #000;
            }

            li.sub-cat ul.sub-menu li a:hover,
            li.sub-cat ul.sub-menu li ul li a:hover {
                color: #ED6E26 !important;
            }



        }
    }

}

.category-header {
    position: relative;
    background: {
        color: $pastel-1;
    }
    &:before {
        position: absolute;
        z-index: 1;
        width: 45%;
        height: 100%;
        bottom: 0;
        left: 0;
        color: $pastel-1-accent;
        content: "";
        background-image: url(/images/w.svg);
        background-size: 45%;
        background-position: bottom left;
        background-repeat: no-repeat;
        opacity: .20;
    }
    h1 {
        position: relative;
        z-index: 2;
        display: inline-block;
        font-size: 48px;
        font-weight: 900;
        position: relative;
    }
}

.category-detail {
    position: relative;
    /*overflow-x: hidden;*/

    .category-detail-main {

        #overview-products .product-square .product-info {
            padding: 10px 0 15px 0 !important;
            margin-left: 0;
            margin-right: 0;

            .product-price {
                /*font-family: $main-font;*/
                /*font-family: 'Calibri', sans-serif;*/
                font-family: $primaryFont;
            }

            .product-title {
                margin-bottom: 0;
                font-size: $px16 !important;
                line-height: $px24;
                font-weight: 700;
                margin-top: $px20;
            }

            /*.product-title,*/
            .productpricespan {
                font-size: 18px !important;
                font-weight: 300;
            }
        }

        .category-filters-container {

            h4 {
                /*font-family: $main-font;*/
                font-family: $primaryFont;
              /*  color: $brown;
                font-weight: 400;*/
                font-size: $px20 !important;
                line-height: $px30;
                font-weight: 700;
                margin-bottom: 0 !important;
                padding-bottom: 0;
                color: $primaryColor;
                text-transform: uppercase;

                > div {

                    > div:first-child {
                        font-family: $primaryFont;
                        color: $primaryColor;
                        font-weight: 700;
                    }

                    /*> div:last-child {
                        display: none;
                    }*/
                }
            }

            .category-filter {

                .category-filter-item > div {
                    flex-wrap: inherit;
                    gap: 10px;
                    position: relative;
                    border-bottom: 1px solid #D9D9D9;
                }

                .category-filter-item > div > svg {
                    position: absolute;
                    top: 50%;
                    right: 0%;
                    -webkit-transform: translate(0%, -50%);
                    -moz-transform: translate(0%, -50%);
                    -ms-transform: translate(0%, -50%);
                    -o-transform: translate(0%, -50%);
                    transform: translate(0%, -50%);
                    font-size: 13px;
                }

                .category-filter-item[aria-expanded="true"] > div > svg {
                    transform: rotate(90deg);
                    transition: 0.25s ease-in-out;
                    /*top: 25%;*/
                    top: 33.33%;
                }

                .category-filter-item[aria-expanded="false"] > div > svg {
                    transform: rotate(0deg);
                    transition: 0.25s ease-in-out;
                }

                a.main-cat {
                    font-family: $primaryFont;
                    font-size: $px18;
                    line-height: $px45;
                    color: $secondaryColor;
                }

                a.active {
                    font-weight: 700;
                }

                .sub-cat > a  {
                    padding-left: 0;
                    padding-right: 0;

                    > div {
                        /*font-family: $main-font;*/
                        font-family: $primaryFont;
                        line-height: $px36;
                        font-size: $px18;
                        border-bottom: none;
                    }
                }

                a.active {
                    > div {
                        /*font-family: $main-font;*/
                        font-family: $primaryFont;
                        line-height: $px36;
                        font-size: $px18;
                        color: $secondaryColor;
                    }
                }

                a:hover,
                a:focus {
                    text-decoration: none;
                    color: $primaryColor;
                }

                a.active:hover,
                a.active:focus {
                    text-decoration: none;
                    color: $primaryColor;
                }

                a.active:hover div,
                a.active:focus div {
                    color: $primaryColor;
                }

                .filter-form label {
                    font-family: $primaryFont;
                    font-size: $px18;
                    line-height: $px45;
                    font-weight: 400;
                }
            }

            .category-filter:last-child {

                > div:nth-last-child(1),
                > div:nth-last-child(2) {
                    display: none;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 50%;
            height: calc(100%);
            transform: translateX(-25%);
            opacity: .6;
            background: {
                image: url("/images/bg-trending-left.png");
                repeat: repeat-y;
            }
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            width: 50%;
            height: calc(100%);
            transform: translateX(20%);
            opacity: .6;
            background: {
                image: url("/images/bg-trending-right.png");
                repeat: repeat-y;
                position: right;
            }
        }
    }
}

.home-contact-section .home-contact {

    .title-top {
        font-family: $main-font;
    }

    .title-bottom {
        font-family: $main-font;
    }
}

.category-detail-main #overview-products {}

#overview-products .product-square .image-container {
    height: 210px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.category-detail-main-grid {
    padding-top: $px42;
    padding-bottom: $px70;
}

.pager > div > div {
    flex-grow: 1;
    flex-basis: 0;
}

.pager .prev-btn {
    margin-right: auto;
    padding-left: 0px;
}

.pager .disabled span {
    font-family: "Inter-SemiBold", sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #000;
}

.category-detail-main-grid .prev-btn,
.category-detail-main-grid .next-btn {

}

.category-detail .search-field-inner .col-md-10 {padding-right: 0;}
.category-detail .search-field-inner .col-md-10 input {width: 100%;height: 40px;padding-left: 20px;padding-right: 20px;}
.category-detail .search-field-inner .col-md-2 {
    padding-left: 0;
}

.category-detail .search-field-inner .btn {
    width: 100px;
}

.category-detail #overview-products {
    padding-top: 10px;
}


.brand-title {
    padding-top: 15px;
    padding-bottom: 15px;
}

@include media-breakpoint-up(md){
    .collapse.collapse-on-mobile{
        display: block;
        height: auto !important;
        visibility: visible;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .category-detail .category-detail-main > div > div:first-child {
        padding-left: 0;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .category-detail > .category-detail-main .category-filters-container {
        padding-left: 0;
    }
}



@media (min-width: 1200px) {
    .category-detail-main .category-detail-main-grid > .col-xl-3 {
        flex: 0 0 20.30%;
        max-width: 20.30%;
    }

    .category-detail-main .category-detail-main-grid > .col-xl-9 {
        flex: 0 0 79.70%;
        max-width: 79.70%;
    }
}



@media (max-width: 991px) {
    .category-detail-main-grid {
        padding-top: $px12;
        padding-bottom: $px20;
    }
}
