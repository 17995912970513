.site-footer {

    .footer-logo-mobile-div {
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }

    * {
        line-height: $px30;
    }

    a {
        color: $secondaryColor;
    }

    a:hover,
    a:focus {
        color: $primaryColor;
        text-decoration: none;
    }

    padding: {
        top: 80px;
        bottom: 70px;
    }


    @media (min-width: 960px) {
        .footer-col4 {
            text-align: right;
            padding-top: $px42;
        }
    }

    @media (max-width: 959px) {
        .footer-col3 .footer-logo-mobile {
            display: none;
        }
    }

    @media (min-width: 768px) {
        .footer-col1 > div {
            text-align: left;
        }
    }

    @media (max-width: 767px) {
        .footer-col1 {
            margin-bottom: 0;
        }
    }
}



@media (max-width: 991px) {
    .site-footer {
        padding-top: $px40;
        padding-bottom: $px40;
    }

    .cart-totals-html-mobile {
        margin-bottom: 0 !important;
    }
}


@media (min-width: 768px) and (max-width: 991px) {
    .footer-col1,
    .footer-col2 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .footer-col3 {
        flex: 0 0 0%;
        max-width: 0%;
        padding: 0;
    }

    .footer-col4 {
        flex: 0 0 50%;
        max-width: 50%;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        padding-top: 40px;
    }
}



@media (max-width: 767px) {
    .site-footer .footer-col4 {order: 1;-webkit-order:1;text-align: center;}
    .site-footer .footer-col1 {order: 2;-webkit-order:2;text-align: center;margin-top: $px30;margin-bottom: $px30;}
    .site-footer .footer-col2 {order: 3;-webkit-order:3;text-align: center;}
    .site-footer .footer-col3 {order: 4;-webkit-order:4;text-align: center;}
}
