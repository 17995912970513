.home-header-usps {
    padding-top: $px30;
    padding-bottom: $px30;
    margin-left: 0;
    margin-right: 0;
}

.home-header-usps img  {
    height: 40px;
    object-fit: contain;
}

.home-header-usps .col-md-3 {
    position: relative;
}


.home-header-usps .col-md-3 > div:nth-child(1) {
    position: absolute;
    top: 5px;
    left: 20px;
}

.home-header-usps .col-md-3 > div:nth-child(2) {
    padding-left: 60px;
}

.home-header-usps p {
    font-size: $px15;
    /*line-height: $px30;*/
    line-height: $px21;
    margin-bottom: 0;
}

.home-header-usps span {
    display: block;
    font-size: $px18;
    line-height: $px30;
    text-transform: uppercase;
}


.home-new-products-slider > div {
    padding-left: 0;
    padding-right: 0;
}

.home-volledig-aanbod {
    margin-top: 25px;
    padding-bottom: $px58;
}

.home-main > div > div > div:nth-child(2),
.over-ons-main > div > div > div:nth-child(2) {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}

.home-bottom > div {
    position: relative;
    max-width: none;
}

/*.home-bottom > div > div:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 30px);
    height: 100%;
    overflow: hidden;
}*/

.home-bottom > div {
    padding-left: 0;
    padding-right: 0;
}

.home-bottom > div > div:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin-left: 0;
    margin-right: 0;
}

.home-bottom > div > div:nth-child(1) > div {
    padding-left: 0;
    padding-right: 0;
}

.home-bottom > div > div:nth-child(2) {
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 0;
    margin-right: 0;
}


.overlay-dark {
    position: relative;
    background: rgba($secondaryColor, 0.6);
    padding-top: $px70;
    padding-bottom: $px70;
}

.overlay-dark::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($secondaryColor, 0.6);
}

/*
.overlay-dark {
    background: rgba($secondaryColor, 0.2);
}*/

/*
.home-bottom > div > div:nth-child(2) {
    background: rgba($secondaryColor, 0.2);
}*/


.home-bottom-categories-block-inner {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: column;
    -webkit-flex-direction: column;
    background: $primaryColor;
    height: 205px;
    position: relative;
    border: 5px solid $primaryColor;
}

.home-bottom-categories-block-inner:hover,
.home-bottom-categories-block-inner:focus {
    background: rgba($secondaryColor, 0.5);
    border: 5px solid $primaryColor;
}

.home-bottom-categories-block-inner img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin-bottom: 25px;
}

.home-bottom-categories-block-inner span {
    font-weight: 500;
    font-size: $px18;
    line-height: $px30;
    text-transform: uppercase;
}

.home-bottom-categories-block-inner a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/*
.home-bottom-categories-block-inner a:hover {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}*/


.home-bottom .overlay-dark > div:nth-child(1) {

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;

}

.home-main .orange-btn-link,
.home-bottom .orange-btn-link {
    margin-top: 20px;
    display: inline-block;
    padding: 0.325rem 1.4375rem;

}

.bottom-usps {
    padding-left: 0;
    padding-right: 0;
}

.bottom-usps > div {
    max-width: 1360px;
    margin-left: auto;
    margin-right: auto;
}

.btn-primary:focus, .btn-primary.focus,
.btn-secondary:focus, .btn-secondary.focus,
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none !important;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    background-color: $secondaryColor !important;
    border-color: $secondaryColor !important;
    box-shadow: none !important;
}

.no-products-in-cart a {
    line-height: 1.875rem;
}

.txt-link {
    color: $secondaryColor;
}

.txt-link:hover,
.txt-link:focus {
    color: $primaryColor;
    text-underline-position: under;
}

.card, .form-control, .select2-container .selection .select2-selection {
    border-radius: 0px !important;
}

.error-page {
    text-align: center;
}

.register-link, .login-link {
    color: $primaryColor;
    text-decoration: underline;
    text-underline-position: under;
}

.register-link:hover,
.register-link:focus,
.login-link:hover,
.login-link:focus {
    color: $secondaryColor;
    text-decoration: underline;
    text-underline-position: under;
}

.filter-mob {
    margin-left: 0;
    margin-right: 0;
}

.category-detail-main-grid  > .d-md-block > .filter-mob, .product-cat-nav > .filter-mob {
    margin-left: -15px;
    margin-right: -15px;
}

#mobile-filter {
    margin-bottom: $px20;
}

input, textarea, select {-webkit-appearance: none !important;}

@media (min-width: 960px) {
    .home-main, .over-ons-main, .error-page {
        padding-top: $px70;
        padding-bottom: $px70;
    }

    .home-main > div > div > div:nth-child(2),
    .over-ons-main > div > div > div:nth-child(2) {
        padding-left: $px55;
        padding-right: $px55;
    }

    .home-bottom .overlay-dark > div:nth-child(1) {
        padding-left: $px55;
        padding-right: $px55;
        max-width: 750px;
        margin-left: auto;
    }

    .home-bottom .overlay-dark > div:nth-child(1) > div {
        max-width: 585px;
    }

    .home-bottom .overlay-dark > div:nth-child(2) {
        /*max-width: 750px;*/
        max-width: 696px;
        margin-right: auto;
    }

}




.cart-content-section .cart-page-cart-content .cart-contents > div {
    margin-top: 0 !important;
}


.checkout-page-form input:not([type=checkbox]):not([type=radio]),
.checkout-page-form select {
    height: 40px;
    border-radius: 0;
    margin-bottom: 20px;
}


.delivery-address-choice .form-group {
    margin-bottom: 0;
}

.notes-row {
    margin-top: 20px;
}

.voorwaarden-link, .privacy-policy-link {
    color: $secondaryColor;
    text-decoration: underline;
    text-underline-position: under;
}

.voorwaarden-link:hover,
.voorwaarden-link:focus,
.privacy-policy-link:hover,
.privacy-policy-link:focus {
    color: $primaryColor;
    text-decoration: underline;
    text-underline-position: under;
}


.checkout-page-form-data .cart-content-header {
    padding-top: 0 !important;
}

.shop-user-register-section input:not([type=checkbox]):not([type=radio]),
.shop-user-register-section select,
.contact-form input:not([type=checkbox]):not([type=radio]) {
    height: 40px !important;
}

.contact-form input,
.contact-form textarea {
    font-family: $primaryFont;
    color: $secondaryColor;
    font-style: normal;
    font-weight: 400;
    font-size: $px18 !important;
    line-height: $px27 !important;
}

.contact-form .privacy-notice-paragraph,
.contact-form .privacy-notice-paragraph a {
    font-size: 12px;
    line-height: 18px;
}

.contact-form .privacy-notice-paragraph a {
    color: $secondaryColor;
    text-decoration: underline;
    text-underline-position: under;
}

.contact-form .privacy-notice-paragraph a:hover,
.contact-form .privacy-notice-paragraph a:focus {
    color: $primaryColor;
    text-decoration: underline;
    text-underline-position: under;
}

.privacy-notice-paragraph::before {
    display: none !important;
}

.contact-form button {
    font-size: 1rem;
    line-height: 1.875rem;
    font-style: normal;
    font-weight: 400;
    border: none;
    background-color: $primaryColor;
    color: $white;
    text-decoration: none;
    padding: 0.325rem 1.4375rem;
    transition: 0.2s all ease;
    -webkit-transition: 0.2s all ease;
}

.contact-form button:hover,
.contact-form button:focus {
    border: none;
    background-color: $secondaryColor;
    color: $white;
}

.contact-form .form-group {
    margin: 20px 0px !important;
}

.contact-data a {
    color: $secondaryColor;
    text-decoration: none;
}

.contact-data a:hover,
.contact-data a:focus {
    color: $primaryColor;
    text-decoration: none;
}

.privacyverklaring-main-content-table-desktop table {
    border-collapse: collapse;
}

.privacyverklaring-main-content-table-desktop table th,
.privacyverklaring-main-content-table-desktop table td {
    border: 1px solid $secondaryColor;
    padding: 5px 15px 5px 5px;
}


.legal-main h1 {
    text-transform: uppercase;
    font-weight: 600;
}

.shop-user-register-section > div > div {
    padding-left: 0;
    padding-right: 0;
}

.brand-filter-block + .category-filter {
    display: none;
}

@media (min-width: 992px) {
    .cart-totals-html-mobile {
        display: none;
    }

    .contact-main, .legal-main, .shop-user-register-section {
        padding-top: $px70;
        padding-bottom: $px70;
    }

    .contact-data {
        padding-left: 100px;
    }

    .privacyverklaring-main-content-table-mobile {
        display: none;
    }

}

@media (max-width: 991px) {

    .mobile-nav-categories {
        margin-top: $px20;
        margin-left: -15px;
        margin-right: -15px;
        max-width: none;
        width: calc(100% + 30px);
        flex: 0 0 calc(100% + 30px)
    }

    .mobile-nav-categories .menu-categorie-menu {
        padding-left: 0;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: space-around;
        -webkit-justify-content: space-around;
        padding-top: $px20;
        padding-bottom: $px20;
    }

    .mobile-nav-categories + .main-nav-container {
        display: none;
    }

    .uk-sticky-fixed .mobile-nav-categories {
        display: none;
    }


    .cart-totals-html-desktop {
        display: none;
    }

    .contact-main, .legal-main, .error-page, .shop-user-register-section {
        padding-top: $px40;
        padding-bottom: $px40;
    }

    .contact-data {
        padding-top: 40px;
    }

    .privacyverklaring-main-content-table-desktop {
        display: none;
    }

}


@media (min-width: 992px) and (max-width: 1400px) {

    .container {
        max-width: none;
    }

    .header-desktop > div.container-fluid-desktop > div:nth-child(1) {
        padding-left: 30px;
        padding-right: 30px;
    }


    ul.menu-main-menu > li > a, ul.menu-main-menu > li > a:visited, ul.menu-main-menu > li > span, ul.menu-language-items > li > a, ul.menu-language-items > li > a:visited, ul.menu-language-items > li > span {
        padding: 12px 0px !important;
    }

    header .col-md-6 {
        flex: 0 0 48%;
        max-width: 48%;
    }

    header .col-md-3 {
        flex: 0 0 26%;
        max-width: 26%;
    }

    .category-detail-main {
        padding-left: 35px;
        padding-right: 35px;
    }

    .catsort .col-8 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .catsort .col-1 {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .catsort .col-3 {
        flex: 0 0 30%;
        max-width: 30%;
    }

    .search-field-inner .col-md-10 {
        flex: 0 60%;
        max-width: 60%;
    }
    .search-field-inner .col-md-2 {
        flex: 0 0 40%;
        max-width: 40%;
    }

    body .home-header-usps {
        padding-left: 20px;
        padding-right: 20px;
    }

    body #product-detail .product-info .product-title {
        line-height: $px30;
    }

    body #product-detail .product-info {
        padding-left: $px40;
        padding-right: 0;
    }

    .cart-content-section .cart-page-cart-content-totals {
        margin-left: 0px;
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .cart-content-section .cart-page-cart-content-totals .pl-4 {
        padding-left: 0 !important;
    }

    .checkout-page-form-data .cart-totals-html {
        margin-left: 0px;
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .checkout-page-form-data .cart-totals-html .pl-4 {
        padding-left: 0 !important;
    }

}



@media (min-width: 992px) and (max-width: 1199px) {
    .category-detail-main .category-detail-main-grid > .col-xl-3,
    .product-info-row .product-cat-nav {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .category-detail-main .category-detail-main-grid > .col-xl-9,
    .product-info-row .product-main {
        flex: 0 0 75%;
        max-width: 75%;
    }

    body #overview-products > div {
        flex: 1 1 50%;
    }

    #product-detail .product-info .qty-add-to-cart-max div.col-4 {
        margin-bottom: $px20;
    }

    #product-detail .product-info .qty-add-to-cart-max div.col-8 {
        padding-left: 0;
    }

}

@media (min-width: 1200px) {

    body #overview-products > div {
        flex: 1 1 25%;
    }

    .home-bottom-categories-block {
        margin-left: -26px;
    }
    .home-bottom-categories-block > div {
        padding-left: 26px;
    }

    .home-bottom .home-bottom-categories-block>.uk-grid-margin {
        margin-top: 26px;
    }
}




@media (max-width: 1199px) {
    #product-detail .home-offer-slider-desktop ul.uk-slider-items > div {
        padding-left: 15px;
        padding-right: 0;
    }
}




@media (min-width: 768px) and (max-width: 991px) {
    .category-detail .search-field-inner .col-md-10 {
        flex: 0 0 60%;
        max-width: 60%;
    }

    .category-detail .search-field-inner .col-md-2 {
        flex: 0 0 40%;
        max-width: 40%;
    }
}


@media (max-width: 991px) {


    .catsort {
        margin-bottom: 2rem;
    }

    .catsort .search-field {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .catsort .catippdiv {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .catsort .productsort {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .catsort .row {
        margin-left: 0;
        margin-right: 0;
    }

    .category-detail .search-field-inner .btn {
        width: 100% !important;
    }

    .category-detail .search-field-inner .col-md-10 {
        padding-left: 0;
    }

    .category-detail .search-field-inner .col-md-2 {
        padding-right: 0;
    }

    .home-main, .over-ons-main {
        padding-top: $px40;
        padding-bottom: $px20;
    }

    .overlay-dark {
        padding-top: $px40;
        padding-bottom: $px40;
    }

    body .home-search .do-search > div > div.col-md-10 {
        flex: 0 0 78.667%;
        max-width: 78.667%;
    }

    .home-search {
        padding-top: $px40;
    }

    .home-nieuwste-producten {
        margin-top: -53px;
    }

    .home-volledig-aanbod {
        padding-bottom: $px20;
    }

    .home-main > div > div > div:nth-child(2),
    .over-ons-main > div > div > div:nth-child(2),
    .home-bottom .overlay-dark > div:nth-child(2) {
        margin-top: 40px;
    }

    .container {
        max-width: none;
    }

    header .container-fluid-desktop {
        display: none;
    }

    .container-fluid-mobile {
        /*background: #000;*/
        padding-left: 25px !important;
        padding-right: 25px !important;
    }

    .header-mobile-col8 {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
    }

    .mobile-menu-block {
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 75vw;
        background: #fff;
        pointer-events: none;
        transform: translateX(-100%);
        opacity: 0;
        transition: all 300ms ease;
    }

    .mobile-menu-block.open {
        z-index: 9999;
        opacity: 1;
        transform: translateX(0);
        pointer-events: all;
        background-color: transparent;
        /*background: linear-gradient(180deg, #0E4963 0%, #0097D2 100%);*/
        background: $secondaryColor;
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
    }

    .mobile-menu-block.open > * {
        position: relative;
        z-index: 0;
    }

    .mobile-menu-block .close-menu {
        text-align: right;
        padding: 20px;
        font-size: 1.5rem;
        color: $white;
    }

    .mobile-menu-block .close-menu:hover {
        color: $primaryColor;
    }

    .menu-mobile-menu {
        list-style: none;
    }

    .menu-mobile-menu li + li {
        margin-top: $px16;
    }

    .menu-mobile-menu li a {
        font-size: $px20;
        line-height: $px30;
        color: $white;
        text-decoration: none;
    }

    .menu-mobile-menu li a:hover,
    .menu-mobile-menu li a:focus {
        color: $primaryColor;
        text-decoration: none;
    }

    .mobile-menu-block.open .user-login-link img,
    .mobile-menu-block.open .mini-cart img {
        filter: brightness(0) invert(1);
    }

    .mobile-menu-block.open .user-login-link::before {
        color: $white;
    }

    .mobile-menu-block-open-bottom {
        margin-top: 40px;
    }

    .mobile-menu-block.open .user-login-link {
        margin-left: 10px;
        margin-right: 25px;
    }

    .mobile-menu-block.open .user-login-link::before {
        top: 152%;
        left: 44%;
    }

    .mobile-menu-block.open a:hover,
    .mobile-menu-block.open a:focus {
        text-decoration: none !important;
    }


}

@media (max-width: 959px) {
    .home-bottom .container .bg-image > div > div {
        height: 100%;
    }

    .home-bottom .container .bg-image > div > div img {
        height: 100%;
        object-fit: cover;
    }

    .deliveryAddressBlock {
        margin-bottom: 15px;
    }

    .notes-row {
        margin-top: 15px;
    }

}


@media (max-width: 920px) {
    .home-nieuwste-producten {
        margin-top: 0px;
        padding-top: 20px;
    }
}


@media (min-width: 768px) and (max-width: 991px) {
    .home-header-usps .col-md-3 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .home-header-usps img {
        height: 30px;
    }

    .home-header-usps .col-md-3 > div:nth-child(1) {
        left: 10px;
    }

    .home-header-usps .col-md-3 > div:nth-child(2) {
        padding-left: 40px;
    }

    .home-header-usps span {
        font-size: $px16;
        line-height: $px24;
    }

    .home-header-usps > div:nth-child(2),
    .home-header-usps > div:nth-child(3) {
        margin-left: 15px;
        margin-right: -15px;
    }

    .container-fluid-mobile > div > div.col-3 {
        flex: 0 0 35%;
        max-width: 35%;
    }


    .container-fluid-mobile > div > div.col-6 {
        flex: 0 0 30%;
        max-width: 30%;
    }

    body #overview-products > div {
        flex: 1 1 50%;
    }


}

@media (max-width: 767px) {
    .checkout-page-form input:not([type=checkbox]):not([type=radio]), .checkout-page-form select {
        margin-bottom: 15px;
    }

    .mini-cart .cart-title,
    .mini-cart .cart-total {
        display: none;
    }

    .mini-cart a, .mini-cart a.visited {
        min-width: auto !important;
    }

    .user-login-link {
        margin-left: 25px;
    }

    body #overview-products > div {
        flex: 1 1 100%;
    }

}


@media (min-width: 640px) and (max-width: 767px) {
    .home-header-usps .col-md-3 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .home-header-usps .col-md-3:nth-child(1),
    .home-header-usps .col-md-3:nth-child(2) {
        margin-bottom: 20px;
    }
}

@media (max-width: 639px) {
    .home-header-usps .col-md-3 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .home-header-usps .col-md-3 + .col-md-3 {
        margin-top: 20px;
    }
}



@media (max-width: 539px) {
    body .home-search .do-search > div > div.col-md-10,
    body .home-search .do-search > div > div.col-md-2 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    body .home-search .btn {
        min-width: 100%;
    }
}


@media (max-width: 479px) {
    .logincontainer .butn-login + .btn-link {
        display: block;
        margin-top: 20px;
        padding-left: 0px;
        text-align: left;
    }
}
