body {
    font-family: $primaryFont;
    color: $secondaryColor;
    font-style: normal;
    font-weight: 400;
    font-size: $px18;
    line-height: $px27;
    overflow-x: hidden;
}

.uk-button.uk-button-default {
    width: 271px;
    padding-top:10px;
    padding-bottom: 10px;
    border-radius: 7px;
    background-color: $white;
    transition: all 300ms ease-in-out;
    font-size: 18px;
    &:hover {
        background-color: $black;
        color: $white;
    }
}
.uk-button.btn-dark {
    background-color: $main-color;
    color: $white;
}

input:not([type="checkbox"]):not([type="radio"]) {
    /*border: 1px solid $pastel-3-accent;*/
    /*border: 1px solid $brown-v1;*/
    border: 1px solid #C4C4C4;
    background-color: transparent;
    padding-top: 5px;
    padding-bottom: 5px;

}

.uk-select:not([multiple]):not([size]) {
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 20px;
    background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='black'/></g></svg>");
    background-repeat: no-repeat;
    background-position: 94% 50%;
    /*border: 1px solid $pastel-3-accent;*/
    /*border: 1px solid $brown-v1;*/
    background: rgba(21, 21, 21, 0.03);
    border: 1px solid #D9D9D9;
    /*background-color: transparent;*/
    padding-top: 5px;
    padding-bottom: 5px;
}

.select2-container {
    width: 100% !important;
    .selection {
        height: 38px;
        .select2-selection {
            height: 38px;
            @extend .form-control;
            /*border: 1px solid $pastel-3-accent;*/
            border: 1px solid $primaryColor;
            border-radius: 0;
            background-color: transparent !important;
            padding-top: 5px;
            padding-bottom: 5px;
        }
         .select2-selection__arrow {
             height: 38px !important;
         }
    }
}

.price-input  {
    position: relative;
    &:before {
        position: absolute;
        content: "€";
        top: 8px;
        left: 22px;
        /*color: $pastel-3-accent;*/
        color: $secondaryColor;
    }
    input {
        text-align: right;
    }
}
.break {
    flex-basis: 100%;
    height: 0;
}

.qty-selector {
    position: relative;
    .qty-select-open {
        height: 45px;
        width: 45px;
        /*background-color: $pastel-3;
        border: 1px solid $pastel-3;*/
        background-color: $primaryColor;
        border: 1px solid $primaryColor;
        color: $white;
        text-align: center;
        margin-left: -4px;
        padding: 10px 0;
        cursor: pointer;
    }
    .qty-field {
        /*border: 1px solid $pastel-3;*/
        border: 1px solid $primaryColor;
        height: 45px; width: 80px;
        text-align: center;
        vertical-align: top;

    }
    .qty-select-options {
        position: absolute;
        left: 0;
        z-index: 99;
        top: 100%;
        background-color: $white;
        /*border: 1px solid $pastel-3;*/
        border: 1px solid $primaryColor;
        border-top: none;
        width: 125px;
        height: 200px;
        overflow-y: auto;
        transform: scaleY(0);
        transform-origin: top;
        opacity: 0;
        transition: all 300ms ease-in-out;

        &.open {
            transform: scaleY(1);
            opacity: 1;

        }
        /* width */
        &::-webkit-scrollbar {
            width: 10px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            /*background: $pastel-3;*/
            background: $primaryColor;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
           /* background: $pastel-3;*/
            background: $primaryColor;
        }
        scrollbar-width: thin;
        /*scrollbar-color: $pastel-3 #f1f1f1;;*/
        scrollbar-color: $primaryColor #f1f1f1;
        div {
            &:hover {
                /*background-color: rgba($pastel-3, .6);*/
                background-color: rgba($primaryColor, .6);
            }
        }
    }
}

.page-btn {
    /*background: $light-bg-color;
    border-radius: 100%;
    border: 1px solid $brown;
    margin-right: 10px;
    padding: 0;
    height: 39px;
    width: 39px;
    text-align: center;*/
    border-radius: 100%;
    margin-right: 10px;
    padding: 0;
    height: 44px;
    line-height: 44px;
    width: 19px;
    text-align: center;
    font-family: "Inter-Light", sans-serif;

    span, a {
        color: $black !important;
        font-weight: 300;
    }
}

.page-btn.active {
    /*background: $brown;*/


    span, a {
        /*color: $pastel-1 !important;*/
        color: $primaryColor !important;
        font-weight: 700;
    }
}

.pager {
    /*border-top: 1px solid $brown;*/
    padding-top: 10px;

    .prev-btn {
        margin-right: auto;

        a {
            background: $primaryColor !important;
            color: $white !important;
            padding: 9px 35px;
        }

        a:hover, a:focus {
            background: $secondaryColor !important;
            color: $white !important;
            text-decoration: none;
        }
    }

    .next-btn {
        margin-left: auto;
        padding-right: 0;

        a {
            background: $primaryColor !important;
            color: $white !important;
            padding: 9px 35px;
        }

        a:hover, a:focus {
            background: $secondaryColor !important;
            color: $white !important;
            text-decoration: none;
        }
    }

    .text-end {
        text-align: right;
    }

}



.orange-txt-link {
    color: $primaryColor;
    text-decoration: underline;
    text-underline-position: under;
    font-style: normal;
    font-weight: 700;
}

.orange-txt-link:hover,
.orange-txt-link:focus {
    color: $secondaryColor;
}

.orange-btn-link {
    font-size: $px16;
    line-height: $px30;
    font-style: normal;
    font-weight: 400;
    background-color: $primaryColor;
    color: $white;
    text-decoration: none;
    padding: $px10 $px23;
    transition: 0.2s all ease;
    -webkit-transition: 0.2s all ease;
}

.orange-btn-link:hover,
.orange-btn-link:focus {
    background-color: $secondaryColor;
    color: $white;
    text-decoration: none;
}

.txt-white, .txt-white * {
    color: $white;
}

.txt-orange, .txt-orange * {
    color: $primaryColor;
}

.txt-black, .txt-black * {
    color: $black;
}

.bg-grey {
    background: linear-gradient(180deg, #F5F5F5 0%, rgba(255, 255, 255, 0.00114928) 99.99%, rgba(217, 217, 217, 0) 100%);
}

.bg-black {
    background: $secondaryColor;
}

.bg-orange {
    background: $primaryColor;
}

.btn {
    border-radius: 0;
    min-height: 40px;
}

.btn-primary {
    background-color: $primaryColor;
    border-color: $primaryColor;
}

.btn-primary:hover,
.btn-primary:focus {
    background-color: $secondaryColor;
    border-color: $secondaryColor;
}

:focus-visible {
    outline: none !important;
}


.cart-item-block .qty-selector {
    margin-bottom: 5px;
}

.rest {
    display: inline-block;
}

.administration-cost-rest-shop-user,
.administration-cost-rest {
    border: none !important;
    width: auto;
}

.administration-cost-calculation-note {
    font-size: $px15;
    text-align: center;
}

.cc-banner {
    box-shadow: 0 -13px 42px 10px rgb(0 0 0 / 5%);
}
