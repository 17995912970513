$primaryFont: Roboto, sans-serif;

$main-font: aviano-sans, sans-serif;
$main-font1: aviano-sans, sans-serif;
$main-font3: calibri, sans-serif;
$bold-font:  'Caladea', serif;
$h-font: 'Roboto', sans-serif;
$hand-font: 'Dream  Catcher Regular', serif;



.handwriting {
    font-family: $hand-font;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
    content: "\e900";
    transform: translateY(2px);
    display: inline-block;
}
.icon-user:before {
    content: "\e901";
    transform: translateY(2px);
    display: inline-block;
}
.icon-shopping-cart:before {
    content: "\e902";
    transform: translateY(2px);
    display: inline-block;
}
.icon-heart:before {
    content: "\e903";
    transform: translateY(2px);
    display: inline-block;
}

h1, h2, h3, h4, h5, h6 {
    font: {
        family: $h-font;
        weight:600;
    }

}
h1 {
    text-transform: uppercase;
}

strong, b {
    /*font-family: $bold-font;*/
    font-family: $primaryFont;
}



body .uk-h1, body .uk-h2, body .uk-h3, body .uk-h4, body .uk-h5, body .uk-h6, body .uk-heading-2xlarge, body .uk-heading-large, body .uk-heading-medium, body .uk-heading-small, body .uk-heading-xlarge, body h1, body h2, body h3, body h4, body h5, body h6 {
    font-family: $primaryFont;
}

body .uk-h2, body h2 {
    font-style: normal;
    font-weight: 600;
    color: $secondaryColor;
    text-transform: uppercase;
}


.bold, .strong {
    font-weight: 700;
}


@media (min-width: 960px) {
    body .uk-h2, body h2 {
        font-size: $px28;
        line-height: $px34;
    }
}


@media (max-width: 767px) {
    body .uk-h1, body h1 {
        font-size: 1.83125rem;
        line-height: 1.2;
    }


    body .uk-h2, body h2 {
        font-size: 1.5rem;
        line-height: 1.3;
    }

    body .uk-h3, body h3 {
        font-size: 1.3rem;
        line-height: 1.4;
    }
}
