
header {
    position: sticky;
    top:0;
    z-index: 1010;
   /* background-color: $beige;*/
    background-color: #FFF;
    /*box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;*/

    @media (min-width: 960px) and (max-width: 1199px) {
        > div:last-child > div > div:nth-child(3) {
            padding-right: 45px;
        }
    }
}

.mini-cart {
    position: relative;
    a, a.visited {
        color: $main-color;
        position: relative;
        display: inline-block;
        min-width: 190px;
        text-align: left;
    }
    .badge-main {
        background-color: $primaryColor;
        position: absolute;
        /*right: -13px;*/
        left: 16px;
        top: -5px;
        color: $white;
        z-index: 999;
    }
}


.mini-cart .cart-title {
    font-family: $primaryFont;
    font-style: normal;
    font-weight: 400;
    font-size: $px15;
    line-height: $px18;
    color: rgba($black, 0.5);
    position: absolute;
    top: 20%;
    left: 60px;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}

.mini-cart .cart-total {
    font-family: $primaryFont;
    font-style: normal;
    font-weight: 400;
    font-size: $px18;
    line-height: $px21;
    color: $black;
    position: absolute;
    top: 80%;
    left: 60px;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}


.home-header-title > div {
    position: relative;
}

.home-header-title > div > div:nth-child(2) {
    position: absolute;
    top: 50%;
    right: 0%;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    padding: $px30 $px150 $px30 $px50;
    background: rgba($white, 0.7);
}

.home-header-title h1 {
    margin-bottom: 0;
    max-width: $px320;
    font-style: normal;
    font-weight: 600;
    font-size: $px48;
    line-height: $px58;
    color: $secondaryColor;
    text-transform: uppercase;
}

.home-header .home-header-title img {
    height: 448px;
    object-fit: cover;
}




.user-login-link {
    position: relative;
    /*top: -5px;*/
    top: 0px;
    right: 2px;
}

.user-login-link::before {
    /*content: 'Login';*/
  /*  content: attr(data-before);
    display: inline-block;
    position: absolute;
    top: 150%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-family: $primaryFont;
    font-size: $px12;
    line-height: $px14;
    font-weight: 300;
    text-transform: uppercase;
    color: $black;*/
}

.user-login-link img {
   /* width: 27px;
    height: 27px;*/
    width: 47px;
    height: 41px;
}

.user-login-link:hover img {
    filter: invert(65%) sepia(53%) saturate(5561%) hue-rotate(346deg) brightness(97%) contrast(91%) !important;
}

.user-login-link:hover::before {
    color: $primaryColor !important;
}



.mini-cart img {
    width: 30px;
    height: 30px;
}


.menu-categorie-menu {
    list-style: none;
    text-align: center;
    margin-bottom: 0;
}

.menu-categorie-menu li {
    display: inline-block;
    padding: 10px 20px;
}

.menu-categorie-menu li a {
    font-weight: 500;
    font-size: $px18;
    line-height: $px30;
    text-transform: uppercase;
    color: $white;
}

.menu-categorie-menu li a:hover,
.menu-categorie-menu li a:focus {
    color: $black;
    text-decoration: none;
}

.menu-categorie-menu li.active a {
    color: $black;
}

.menu-categorie-menu li.active a:hover,
.menu-categorie-menu li.active a:focus {
    color: $white;
}


.menu-main-menu li.active a, .menu-main-menu li.home-active:nth-child(1) a, .menu-mobile-menu li.home-active:nth-child(1) a {
    color: $primaryColor !important;
}

.menu-main-menu li.active a:hover,
.menu-main-menu li.active a:focus,
.menu-main-menu li.home-active:nth-child(1) a:hover,
.menu-main-menu li.home-active:nth-child(1) a:focus {
    color: $secondaryColor !important;
}

.menu-mobile-menu li.active a, .menu-mobile-menu li.home-active:nth-child(1) a {
    color: $primaryColor !important;
}

.menu-mobile-menu li.active a:hover,
.menu-mobile-menu li.active a:focus,
.menu-mobile-menu li.home-active:nth-child(1) a:focus,
.menu-mobile-menu li.home-active:nth-child(1) a:hover {
    color: $white !important;
}


/*
.menu-categorie-menu li:nth-child(1) a {
    position: relative;
}



.menu-categorie-menu li:nth-child(1) a::before {
    content: '';
    display: none;
    background: url('/images/sierlijsten-icon.svg') no-repeat 50% 50%;
    width: 20px;
    height: 20px;
    background-size: contain;
    position: absolute;
    top: 0;
    left: -25px;
    z-index: 1;
}

.menu-categorie-menu li:nth-child(1) a::after {
    content: '';
    display: none;
    background: url('/images/sierlijsten-icon.svg') no-repeat 50% 50%;
    width: 20px;
    height: 20px;
    background-size: contain;
    position: absolute;
    top: 0;
    right: -25px;
    z-index: 1;
}

.menu-categorie-menu li:nth-child(1) a:hover::before,
.menu-categorie-menu li:nth-child(1) a:focus::before,
.menu-categorie-menu li:nth-child(1) a:hover::after,
.menu-categorie-menu li:nth-child(1) a:focus::after {
    display: inline-block;
}


 */

.header-desktop > div {
    max-width: 1360px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 0 !important;
}

.header-desktop > div.container-fluid-desktop > div:nth-child(1) {
    padding-top: 25px;
    padding-bottom: 25px;
}

.home-header-logo {
    padding-left: 0;

}

.home-header-logo > div {
    padding-left: 0;
}

.header-desktop .text-end > div {
    text-align: right;
}

.header-desktop .text-end {
    padding-right: 0;
}


header.uk-sticky-fixed {
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}

.menu-categorie-menu > li.has-sub {
    position: relative;
}

.menu-categorie-menu > li > ul.sub-menu {
    position: absolute;
    z-index: 999;
    display: block;
    background-color: $secondaryColor;
    transform: scaleY(0);
    opacity: 0;
    top: 100%;
    left: 15px;
    transition: opacity 300ms ease;
    /*padding: 20px 0;*/
    padding: 20px;
    width: 260px;
    /* margin-top: 2.2rem; */
}

.menu-categorie-menu > li > ul.sub-menu > li {
    /*margin-bottom: 10px;*/
    /*position: static;*/
    position: relative;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
    display: block;
    padding: 0px;
    text-align: left;
}

.menu-categorie-menu > li > ul.sub-menu > li + li {
    margin-top: 10px;
}

.menu-categorie-menu > li:hover > ul.sub-menu {
    opacity: 1;
    transform: scaleY(1);
}

.menu-categorie-menu > li > ul.sub-menu > li a {
    color: $white;
}

.menu-categorie-menu > li > ul.sub-menu > li a:hover,
.menu-categorie-menu > li > ul.sub-menu > li a:focus {
    color: $primaryColor;
}

.menu-categorie-menu > li > ul.sub-menu > li a.active {
    color: $primaryColor;
}

.menu-categorie-menu > li > ul.sub-menu > li a.active:hover,
.menu-categorie-menu > li > ul.sub-menu > li a.active:focus {
    color: $white;
}


/*
.menu-categorie-menu > li > ul.sub-menu .sub-menu {
    padding-left: 0;
    padding-bottom: 10px;
}

.menu-categorie-menu > li > ul.sub-menu .sub-menu li {
    display: block;
    padding: 0px;
    line-height: 1.2;
    padding-left: 15px;
}

.menu-categorie-menu > li > ul.sub-menu .sub-menu li:first-child {
    padding-top: 10px;
}

.menu-categorie-menu > li > ul.sub-menu .sub-menu li + li {
    padding-top: 10px;
}*/

.menu-categorie-menu > li > ul.sub-menu .sub-menu {
    display: none;
    left: 100%;
    top: 0;
    margin-left: 1px;
    padding: 0px;
    background-color: transparent;
}

.menu-categorie-menu > li > ul.sub-menu .sub-menu li {
    display: block;
}

.menu-categorie-menu > li > ul.sub-menu li:hover .sub-menu {
    display: block;
    position: absolute;
    top: -20px;
    padding: 20px;
    background-color: $secondaryColor;
}

.menu-categorie-menu > li > ul.sub-menu li:hover .sub-menu .sub-menu {
    display: none !important;
}


/*
.menu-categorie-menu > li > ul.sub-menu .sub-menu {
    position: absolute;
    z-index: 999;
    display: block;
    background-color: #101010;
    transform: scaleY(0);
    opacity: 0;
    top: 0%;
    left: 260px;
    transition: opacity 300ms ease;
    padding: 20px;
    width: 260px;
}


.menu-categorie-menu > li:hover > ul.sub-menu .sub-menu {
    opacity: 1;
    transform: scaleY(1);
}*/


@media (min-width: 992px) {
    .container-fluid-mobile,
    .mobile-menu-block {
        display: none;
    }

    .home-header-usps {
        padding-left: $px42;
        padding-right: $px21;
    }

    .home-header-usps > div:nth-child(2) {
        /*padding-left: 60px;*/
        margin-left: 35px;
        margin-right: -35px;
    }


    .home-header-usps > div:nth-child(3) {
        margin-left: 35px;
        margin-right: -35px;
    }


}


@media (max-width: 1099px) {
    .menu-categorie-menu > li > ul.sub-menu {
        display: none;
    }
}

@media (max-width: 991px) {
    .home-header-title h1 {
        font-size: $px36;
        line-height: $px48;
    }

    .home-header-title > div > div:nth-child(2) {
        padding: $px15 $px70 $px15 $px24;
    }

    .menu-categorie-menu > li.has-sub {
        position: relative;
        min-width: 33.33%;
    }
}


/*@media (max-width: 767px) {
    .menu-categorie-menu li:nth-child(1) a {
        content: '';
        display: inline-block;
        background: url('/images/sierlijsten-icon.svg') no-repeat 50% 50%;
        width: 20px;
        height: 20px;
        background-size: contain;
        font-size: 0px;
    }
}*/

@media (max-width: 767px) {
    .menu-categorie-menu > li.has-sub {
        position: relative;
        min-width: 50%;
    }

    .home-header-title > div > div:nth-child(2) {
        position: absolute;
        top: 0%;
        right: 0%;
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        -o-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
    }

}


@media (max-width: 640px) {
    .home-header-title h1 {
        font-size: $px24;
        line-height: $px36;
    }

    .home-header-title > div > div:nth-child(2) {
        padding: $px12 $px40 $px15 $px24;
    }
}

/*
@media (max-width: 400px) {
    .home-header-title > div > div:nth-child(2) {
        -webkit-transform: translate(12.5%, -50%);
        -moz-transform: translate(12.5%, -50%);
        -ms-transform: translate(12.5%, -50%);
        -o-transform: translate(12.5%, -50%);
        transform: translate(12.5%, -50%);
    }
}
*/



@media (max-width: 479px) {
    .menu-categorie-menu > li.has-sub {
        position: relative;
        min-width: 16.666%;
        padding: 0 10px;
    }

    .menu-categorie-menu li a {
        content: '';
        display: inline-block;
        width: 30px;
        height: 30px;
        background-size: contain !important;
        font-size: 0px;
    }

    .menu-categorie-menu li:nth-child(1) a {
        background: url('/images/sierlijsten-icon.svg') no-repeat 50% 50%;
    }

    .menu-categorie-menu li:nth-child(2) a {
        background: url('/images/clipsen-icon.svg') no-repeat 50% 50%;
    }

    .menu-categorie-menu li:nth-child(3) a {
        background: url('/images/houders-icon.svg') no-repeat 50% 50%;
    }

    .menu-categorie-menu li:nth-child(4) a {
        background: url('/images/lijmen-icon.svg') no-repeat 50% 50%;
    }

    .menu-categorie-menu li:nth-child(5) a {
        background: url('/images/sensors-icon.svg') no-repeat 50% 50%;
    }

    .menu-categorie-menu li:nth-child(6) a {
        background: url('/images/gereedschap-icon.svg') no-repeat 50% 50%;
    }

    .menu-categorie-menu li a:hover {
        filter: brightness(0) invert(0);
    }


}
