.product-list-item {
    position: relative;
    border: none;
    height: 100%;
    display: block;
    color: $font-color;
    text-decoration: none !important;
    background-color: transparent;

    .new-label {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        z-index: 10;
        background-color: $primaryColor;
        padding: 2px 10px 2px 10px;
        color: #ffffff;
    }

    .oem-label {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        z-index: 10;
        /*background-color: $primaryColor;*/
        padding: 0px;
        color: #ffffff;
        width: 40px;
        height: 40px;
        object-fit: contain;
    }

    .sale-label {
        position: absolute;
        top: 0;
        display: block;
        z-index: 10;
        background-color: $pastel-2-accent;
        padding: 2px 10px 2px 10px;
        color: #ffffff;

    }

    &:before {
        position: absolute;
        content: "";
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
        z-index: 4;
        opacity: 0;
        transition: all 500ms ease;
    }


    .image-container {
        position: relative;
        /*padding-bottom: 145%;*/
        overflow: hidden;
        background-color: $white;
        img {
            /*position: absolute;
            top:0;
            left:0;
            height: 100%;
            width: 100%;
            object-fit: cover !important;*/
            position: relative;
            object-fit: contain !important;
            width: 100%;
            height: 210px;
        }
    }


    .product-info {
        hyphens: auto;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 15px;
            width: calc(100% - 30px );
            height: 100%;
            opacity:1;
            transition: all 400ms ease;
            background-color: transparent !important;
            padding-left: 0;
            padding-right: 0;
            box-shadow: none !important;
        }
        position: relative;

        .product-title {
            margin-bottom: 0;
            font-size: $px16 !important;
            line-height: $px24;
            font-weight: 700;
            margin-top: $px20;
            @include media-breakpoint-down(md) {
                font-size: 16px !important;
            }
        }
        .product-sku {
            font-size: $px16;
            line-height: $px24;
            font-weight: 400;
            color: $black;
            margin-top: 5px;
        }
        .product-price {
            font: {
                family: $primaryFont;
                size: $px16;
                weight: 600;
            }
            color: $black
        }
    }
    &:hover {
        color: $font-color;
        /*background-color: #f9f5ee;*/
        .product-info {
            &:before {
                background-color: $pastel-1-accent;
            }
        }
        &:before, &:after {
            width: 100%;
            height: 100%;
            opacity: 1;
        }

    }
    .open-icon {
        width: 20px;
    }
}

.coming-soon {
    h2 {
        display: block;
        font-size: 2.5rem;
        &:after {
            display: block;
            content: url('/images/curl.svg');
            width: 200px;
            margin: 20px auto;
        }
    }
}

#overview-products {

    .product-square {

        .product-info {
            background: transparent !important;
            box-shadow: none;

            .product-title {
                color: $primaryColor;
                font-family: $primaryFont;
            }

            /*.product-title,*/

            .productpricespan {
                color: $black;
                font-family: $primaryFont;
            }
        }

        .product-list-item {

            .image-container img {
                max-height: unset;
                max-width: unset;
            }

            @media (max-width: 767px) {
                .image-container img {
                    max-height: 200px;
                }
            }
        }
    }
}
