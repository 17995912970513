.home-nieuwste-producten .uk-slider-items > li > a {
    border: 1px solid rgba($black, 0.3);
    padding: $px20;
}

.home-nieuwste-producten .uk-slider-items > li > a:hover,
.home-nieuwste-producten .uk-slider-items > li > a:focus {
    border: 1px solid rgba($primaryColor, 0.3);
}


@media (min-width: 1200px) {
    .home-nieuwste-producten .uk-slider-items {
        margin-left: -26px;
    }

    .home-nieuwste-producten .uk-slider-items > li {
        padding-left: 26px;
    }

}
