#product-detail .category-filters-container {

    h4 {
        /*font-family: $main-font;*/
        font-family: $primaryFont;
        /*  color: $brown;
          font-weight: 400;*/
        font-size: $px20 !important;
        line-height: $px30;
        font-weight: 700;
        margin-bottom: 0 !important;
        padding-bottom: 0;
        color: $primaryColor;
        text-transform: uppercase;

        > div {

            > div:first-child {
                font-family: $primaryFont;
                color: $primaryColor;
                font-weight: 700;
            }

            /*> div:last-child {
                display: none;
            }*/
        }
    }

    .category-filter {

        .category-filter-item > div {
            flex-wrap: inherit;
            gap: 10px;
            position: relative;
            border-bottom: 1px solid #D9D9D9;
        }

        .category-filter-item > div > svg {
            position: absolute;
            top: 50%;
            right: 0%;
            -webkit-transform: translate(0%, -50%);
            -moz-transform: translate(0%, -50%);
            -ms-transform: translate(0%, -50%);
            -o-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
            font-size: 13px;
        }

        .category-filter-item[aria-expanded="true"] > div > svg {
            transform: rotate(90deg);
            transition: 0.25s ease-in-out;
            /*top: 25%;*/
            top: 33.33%;
        }

        .category-filter-item[aria-expanded="false"] > div > svg {
            transform: rotate(0deg);
            transition: 0.25s ease-in-out;
        }

        a.main-cat {
            font-family: $primaryFont;
            font-size: $px18;
            line-height: $px45;
            color: $secondaryColor;
        }

        a.active {
            font-weight: 700;
        }

        .sub-cat > a  {
            padding-left: 0;
            padding-right: 0;

            > div {
                /*font-family: $main-font;*/
                font-family: $primaryFont;
                line-height: $px36;
                font-size: $px18;
                border-bottom: none;
            }
        }

        a.active {
            > div {
                /*font-family: $main-font;*/
                font-family: $primaryFont;
                line-height: $px36;
                font-size: $px18;
                color: $secondaryColor;
            }
        }

        a:hover,
        a:focus {
            text-decoration: none;
            color: $primaryColor;
        }

        a.active:hover,
        a.active:focus {
            text-decoration: none;
            color: $primaryColor;
        }

        a.active:hover div,
        a.active:focus div {
            color: $primaryColor;
        }

        .filter-form label {
            font-family: $primaryFont;
            font-size: $px18;
            line-height: $px45;
            font-weight: 400;
        }

        .filter-form-mobile label {
            font-family: $primaryFont;
            font-size: $px18;
            line-height: $px45;
            font-weight: 400;
        }
    }

    .category-filter:last-child {

        > div:nth-last-child(1),
        > div:nth-last-child(2) {
            display: none;
        }
    }
}



#product-detail {
    .breadcrumbs a {
        color: $black;
    }

    @media (max-width: 767px) {
        .breadcrumbs > p {
            margin-bottom: 0;
        }
    }

    .breadcrumbs a:last-child {
        font-weight: bold;
    }

    .product-info-row {

        > div:first-child > .row {
            height: 100%;

            img {
                object-fit: cover;
            }
        }
    }

    @media (max-width: 767px) {
        .product-info-row {
            padding-top: 0 !important;
        }
    }

    .product-detail-price {
        font-family: $primaryFont;
        font-style: normal;
        font-weight: 400;
        color: $black !important;
        font-size: 24px;
        margin-top: $px24;
        margin-bottom: $px24;
    }

    .qty-group {
        /*border: 1px solid black !important;*/
        border-radius: 0 !important;

        input {
            color: $black !important;
            /*font-family: aviano-serif, serif;*/
            font-family: $primaryFont;
            border: 1px solid #DDD;
            border-radius: 0;
        }
    }

    .productgallerymainimg {
        padding-left: 0 !important;
        padding-right: 0;
    }

    .image-container-shadow {
        filter: drop-shadow(1px 314px 126px rgba(0, 0, 0, 0.01)) drop-shadow(0px 177px 106px rgba(0, 0, 0, 0.03)) drop-shadow(0px 79px 79px rgba(0, 0, 0, 0.04)) drop-shadow(0px 20px 43px rgba(0, 0, 0, 0.05)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.05));
    }

    .product-info {
        background: var(--white);
        /*box-shadow: 1px 314px 126px rgba(0, 0, 0, 0.01), 0px 177px 106px rgba(0, 0, 0, 0.03), 0px 79px 79px rgba(0, 0, 0, 0.04), 0px 20px 43px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);*/
        padding: 0px 50px;

        .product-title {
            /*font-family: aviano-serif, serif !important;*/
            font-family: $primaryFont !important;
            font-style: normal;
            font-weight: 700;
            color: $secondaryColor !important;
            margin-top: 0;
            margin-bottom: $px20 !important;
            font-size: $px28;
            line-height: $px42;
        }

        .product-brand {
            margin-top: 0 !important;
        }

        .qty-group input {
            min-width: unset;
            max-width: unset;
            width: 100%;
            min-width: 100px;
        }

        @media (min-width: 960px) and (max-width: 1088px) {
            .qty-add-to-cart-max div {
                max-width: unset;
                flex: unset;

                .qty-group {
                    width: 70%;
                    text-align: center;
                }

                .butn.order-btn {
                    width: 70%;
                }
            }
        }

        @media (min-width: 1089px) {
            .qty-add-to-cart-max div.col-8 {
                padding-left: 8px;
            }
        }

        @media (max-width: 462px) {
            .qty-add-to-cart-max {
                flex-direction: column;
                max-width: 100%;
                margin: auto;

                > div {
                    max-width: 100%;
                }
            }
        }

    }

    @media (min-width: 960px) {
        .category-details {
            padding-top: 120px !important;
        }
    }


    @media (max-width: 959px) {
        .category-details {
            padding-top: 80px !important;
        }
    }

    .detailed-fabric-image-row {
        height: 100%;

        > div {
            padding-left: 0;
            padding-right: 0;
        }

        .detailed-fabric-image {
            object-fit: cover;
        }
    }

    .product-square {

        .product-info {

            .product-title {
                color: $black !important;
            }
        }
    }

    .related-products {
        padding-left: 0;
        padding-right: 0;

        .to-webshop-btn {
            display: flex !important;
            margin: 10px auto 110px auto;
            font-size: 16px;
        }

        #related-products {
            padding-top: 20px !important;

            .new-content {
                padding-left: 0;
                padding-right: 0;

                .container {
                    padding-left: 0;
                    padding-right: 0;
                }

                .related-products {

                    .uk-slider-container {

                        .uk-slidenav-previous,
                        .uk-slidenav-next {
                            background: none;
                            color: $black;
                        }

                        .uk-slidenav-previous {
                            transform: translateX(40%) translateY(0);
                        }

                        .uk-slidenav-next {
                            transform: translateX(-40%) translateY(0);
                        }
                    }
                }
            }
        }
    }
}


.link-to-prod-desc a,
.link-to-prod-datasheet a,
.link-to-prod-file a {
    display: inline-block;
    border: 2px solid $primaryColor;
    padding: 5px 20px;
    min-width: 223px;
    font-size: $px16;
    color: $primaryColor;
    text-align: center;
}

.link-to-prod-desc,
.link-to-prod-datasheet,
.link-to-prod-file {
    margin-top: 10px;
    margin-bottom: 10px;
}


.link-to-prod-desc a:hover,
.link-to-prod-datasheet a:hover,
.link-to-prod-file a:hover,
.link-to-prod-desc a:focus,
.link-to-prod-datasheet a:focus,
.link-to-prod-file a:focus {
    border: 2px solid $secondaryColor;
    background: $secondaryColor;
    color: $white;
    text-decoration: none;
}

.login-div {
    margin-top: $px20;
    margin-bottom: $px45;
    font-size: 1rem;
}

.login-div a {
    color: $secondaryColor;
    text-decoration: underline;
    text-underline-position: under;
}

.login-div a:hover,
.login-div a:focus {
    color: $primaryColor;
    text-decoration: underline;
    text-underline-position: under;
}

#product-detail .product-main-img > div > div {
    padding-left: 0;
    padding-right: 0;
}


.product-main-inner-content {
    border-top: 2px solid $primaryColor;
    position: relative;
    z-index: 0;
    margin-top: $px58;
    /*background: linear-gradient(180deg, #F5F5F5 0%, rgba(255, 255, 255, 0.00114928) 99.99%, rgba(217, 217, 217, 0) 100%);*/
}

.product-main-inner-content::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #F5F5F5 0%, rgba(255, 255, 255, 0.00114928) 99.99%, rgba(217, 217, 217, 0) 100%);
    max-height: 575px;
    z-index: -1;
}

.product-main-inner-content > div {
    padding: 25px;
}

#product-datasheet {
    padding-top: $px20;
}

#product-file {
    padding-top: $px45;
}

#product-file #overview-products {
    padding-top: $px30;
}

#product-datasheet .datasheet-table-head > div {
    border: 1px solid #ddd;
}

#product-datasheet .datasheet-table-row > div {
    border: 1px solid #ddd;
}

#product-datasheet .datasheet-table-row:nth-child(odd){
    background-color: #f9f9f9;
}

#product-datasheet .datasheet-table-row:nth-child(even){
    background-color: #f9f9f9;
}

#product-datasheet .datasheet-table-head,
#product-datasheet .datasheet-table-row {
    margin-left: 0;
    margin-right: 0;
    border-collapse: collapse;
}

#product-datasheet .datasheet-table-head > div,
#product-datasheet .datasheet-table-row > div {
    padding: 8px !important;
}

#product-datasheet a, #product-file a {
    color: $secondaryColor;
}

#product-datasheet a:hover,
#product-datasheet a:focus,
#product-file a:hover,
#product-file a:focus {
    color: $primaryColor;
    text-decoration: none;
}

.form-control:focus, .select2-container .selection .select2-selection:focus {
    box-shadow: none !important;
}


.product-main-inner-content h2 {
    text-transform: none;
}


#product-detail #overview-products .product-square .image-container::before {
    display: none;
}

#product-detail #overview-products .product-square .product-list-item {
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 1.25rem;
}

#product-detail #overview-products .product-info {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
}

#product-detail #overview-products .product-info > div {
    padding-left: 0;
    padding-right: 0;
}


#product-detail .product-main-inner,
#product-detail .product-main-inner-content {
    margin-left: 0;
    margin-right: 0;
}

@media (min-width: 992px) {
    #product-detail {
        padding-top: $px40;
        padding-bottom: $px20;
    }
}


@media (min-width: 1200px) {
    #product-detail .product-cat-nav {
        flex: 0 0 20.3%;
        max-width: 20.3%;
    }
    #product-detail .product-main {
        flex: 0 0 79.7%;
        max-width: 79.7%;
    }

    #product-detail .product-main-img {
        flex: 0 0 36.79%;
        max-width: 36.79%;
    }

    #product-detail .product-info {
        flex: 0 0 63.21%;
        max-width: 63.21%;
    }

    #product-detail #overview-products .product-info {
        flex: 0 0 100%;
        max-width: 100%;
    }

    #product-detail .home-offer-slider-desktop {
        max-width: 806px;
    }

    #product-detail #overview-products .uk-slider-items {
        margin-left: -25px;
    }

    #product-detail #overview-products .uk-slider-items > div {
        padding-left: 25px;
        padding-right: 0;
    }

    #product-detail .product-main-inner {
        padding-left: $px45;
    }

    #product-detail .product-main-inner-content {
        margin-left: $px45;
    }

}


@media (max-width: 1199px) {
    #product-datasheet .datasheet-table-head > div.col-1,
    #product-datasheet .datasheet-table-row > div.col-1 {
        flex: 0 0 20%;
        max-width: 20%;
    }

    #product-datasheet .datasheet-table-head > div.col-10,
    #product-datasheet .datasheet-table-row > div.col-10 {
        flex: 0 0 60%;
        max-width: 60%;
    }

}


@media (max-width: 959px) {

    #product-detail {
        padding-bottom: $px20;
    }

    #product-detail .product-info-row {
      /*  display: grid;
        grid-template-rows: repeat(2, auto);
        grid-template-columns: repeat(4, 1fr);*/

        .product-main-img {
            /*grid-row: 2 / 3;
            grid-column: 1 / 5;*/
            max-width: unset;
        }

        .product-info {
            /*grid-row: 1 / 2;
            grid-column: 1 / 4;*/
            max-width: unset;
        }

        .detailed-fabric-image-div {
            /*grid-row: 1 / 2;
            grid-column: 4 / 5;*/
            max-width: unset;
        }
    }
}


@media (max-width: 875px) {
    #product-detail .product-info-row .product-main-img {
        flex: 0 0 100%;
        max-width: 100%;
    }

    #product-detail .product-info-row .product-info {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
        margin-top: $px40;
    }

    .product-main-inner-content {
        margin-top: $px40;
    }
}

@media (max-width: 767px) {
    #product-detail .product-info-row {

        .product-main-img {
            /*grid-row: 2 / 3;
            grid-column: 1 / 4;*/
            max-width: unset;

            .productgallerymainimg {
                height: 100%;
            }
        }

        .product-info {
            /*grid-row: 1 / 2;
            grid-column: 1 / 5;*/
            max-width: unset;
        }

        .detailed-fabric-image-div {
            /*grid-row: 2 / 3;
            grid-column: 4 / 5;*/
            max-width: unset;
        }
    }
}
