.ui-slider {
    &.ui-slider-horizontal {

        height: 6px;

        .ui-slider-range, &.ui-widget-content {

            /*border: 1px solid $pastel-3-accent;*/
            border: 1px solid $brown-v1;

        }

        .ui-widget-header {
           /* background: $pastel-3-accent;*/
            background: $brown-v1;
            border: none;
        }

        .ui-slider-handle {
            border-radius: 50%;
            width: 14px;
            height: 14px;
           /* background: $pastel-3-accent;
            border-color: $pastel-3-accent;*/
            background: $brown-v1;
            border-color: $brown-v1;
        }

    }
}

.uk-slider .uk-dotnav {
    margin-top: 20px;

    li {
        padding-left: 20px;

        a {
            width: 14px;
            height: 14px;
        }
    }

    li.uk-active a {
        /*background-color: $brown;
        border: 1px solid $brown;*/
        background-color: $primaryColor;
        border: 1px solid $primaryColor;
    }
}

.uk-dotnav>*>:hover {
    background-color: $primaryColor !important;
    border: 1px solid $primaryColor !important;
}

.home-aanbod .home-aanbod-slider {

    .uk-slider-items .image-container {
        img {
            height: 330px;
            width: 100%;
            object-fit: cover;
        }

        @media (max-width: 767px) {
            img {
                height: 165px;
            }
        }
    }
}

.uk-slider {
    .uk-slidenav-previous,
    .uk-slidenav-next {
        color: $black;
    }
}
