
.cart-content-header {
    border-bottom: 1px solid #cccccc;
}
.cart-content-row {
    & + .cart-content-row {
        /*border-top: 1px solid #cccccc;*/
        margin-top: $px40;
    }
}

.cart-contents {
    .delete-link {
        color: rgba($secondaryColor, 0.5);
        text-decoration: none;
        &:hover {
            color: rgba($primaryColor, 0.5);
        }
    }
}

.cart-totals {
    .total-amount {
        border:  1px solid #cccccc;
        background-color: #f9f9f9;
        padding: {
            top: 10px;
            bottom: 10px;
        }
        & + .total-amount {
            border-top: none;

        }
    }
}

.proceed-button {
    position: relative;
    z-index: 1;
    a {
        @extend .uk-button;
        @extend .order-btn;
    }
}

section.cart-header {
    padding: {
        top: 60px;
        bottom: 60px;
    }
    position: relative;
    background: {
        image: url("/images/home-intro-bg.jpg");
        size: cover;
        position: center;
    }
    &:before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $dark-bg-color;
        opacity: .8;
    }
    h1 {
        z-index: 2;
        position: relative;
        margin-top: 110px;
        display: inline-block;
        font-size: 48px;
        color: $light-font-color;
        &:after {
            content: "shop";
            z-index: -1;
            position: absolute;
            bottom: 0;
            right: 50px;
            color: $main-color;
            font: {
                family: $hand-font;
                size: 100px;
                weight: normal
            }
        }
    }
}

body.cart-page, body.checkout-page {
    position: relative;
    @include media-breakpoint-up(lg) {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 50%;
            height: 100%;
            background: {
                image: url("/images/bg-trending-left.png");
                repeat: repeat-y;
            }
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            width: 50%;
            height: 100%;
            transform: scaleX(-1);
            background: {
                image: url("/images/bg-trending-left.png");
                repeat: repeat-y;
                position: left;
            }
        }
    }

}

.checkout-page {
.cart-totals .total-amount:last-of-type {
    position: relative;
}

}

.form-steps {
    list-style-type: none;
    padding-left: 10px;
    li {
        border: 1px solid $pastel-3;

        display: inline-block;
        transform: skew(-24deg);
        margin-right: 5px;
        a, span {
            display: inline-block;
            transform: skew(24deg);
            padding: 10px 30px;
            color: $pastel-3;
        }
        &.enabled, &.current {
                background-color: $pastel-3-accent;
            a, span {
                color: $white;
            }
        }
    }
}


.cart-container, .sp-checkout-section, .confirm-container {
    padding-top: 30px;
}

.cart-container .form-steps, .sp-checkout-section .form-steps, .confirm-container .form-steps {
    padding-left: 0;
}

.cart-container .form-steps li, .sp-checkout-section .form-steps li, .confirm-container .form-steps li {
    border: none;
}

.cart-container .form-steps li,
.cart-container .form-steps li a,
.cart-container .form-steps li span,
.sp-checkout-section .form-steps li,
.sp-checkout-section .form-steps li a,
.sp-checkout-section .form-steps li span,
.confirm-container .form-steps li,
.confirm-container .form-steps li a,
.confirm-container .form-steps li span {
    transform: none;
    color: $secondaryColor;
}

.cart-container .form-steps li a, .cart-container .form-steps li span,
.sp-checkout-section .form-steps li a, .sp-checkout-section .form-steps li span,
.confirm-container .form-steps li a, .confirm-container .form-steps li span {
    padding: 10px 5px 10px 5px;
}

.cart-container .form-steps li.enabled, .cart-container .form-steps li.current,
.sp-checkout-section .form-steps li.enabled, .sp-checkout-section .form-steps li.current,
.confirm-container .form-steps li.enabled, .confirm-container .form-steps li.current {
    background-color: transparent;
}

.cart-container .form-steps li.enabled a, .sp-checkout-section .form-steps li.enabled a, .confirm-container .form-steps li.enabled a {
    color: $secondaryColor;
}

.cart-container .form-steps li + svg, .sp-checkout-section .form-steps li + svg, .confirm-container .form-steps li + svg {
    position: relative;
    left: -3px;
}


.cart-content-section .cart-totals > .row, .sp-checkout-section .cart-totals > .row {
    margin-left: 0;
    margin-right: 0;
}


.cart-content-section .cart-totals .cart-content-header > div, .sp-checkout-section .cart-totals .cart-content-header > div {
    padding-left: 0;
    padding-right: 0;
}

.cart-content-section .cart-item-block {
    border: 1px solid #ddd;
    padding: 1rem;
}

.cart-content-section .cart-item-block .image-container {
    position: relative;
    border: 1px solid #ddd;
}

.cart-content-section .cart-item-block .image-container .oem-label {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    z-index: 10;
    padding: 0px;
    color: #ffffff;
    width: 40px;
    height: 40px;
    object-fit: contain;
}


.deliveryAddressBlock {
    border: 1px solid #C4C4C4;
    padding: 30px 20px 5px 20px;
    margin-bottom: 20px;
    margin-top: 10px;
}


@media (min-width: 992px) {
    .cart-content-section,
    .sp-checkout-section {
        padding-bottom: $px70;
    }
}

@media (max-width: 991px) {



    .cart-content-section,
    .sp-checkout-section {
        padding-bottom: $px40;
    }

    .cart-totals-html-mobile {
        padding: 0;
        margin-top: $px40;
    }

    .cart-totals-html-mobile > div {
        padding-left: 0 !important;
    }

}
